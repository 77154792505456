div.PageWrapper {}

div.content {
    padding: 5px;
    background-color: var(--main-bg-color);
    border-radius: var(--normal-border-radius);
}

div.content div.outer-border {
    margin: 10px;
    border: 1px solid var(--secondary-bg-color);
    border-radius: var(--normal-border-radius);
    box-shadow: 0px 0px 20px 5px var(--secondary-bg-color);
}

div.content div.inner-border {
    margin: 15px;
    padding: 15px;
    border: 1px solid var(--primary-bg-color);
    border-radius: var(--normal-border-radius);
    box-shadow: 0px 0px 20px 5px var(--primary-bg-color);
}