.fc span.fc-list-event-dot {
	border-color: var(--primary-text-color);
}


.fc button.fc-today-button,
.fc button.fc-prev-button,
.fc button.fc-next-button {
	display: none;
}

.fc div.fc-view-harness {
	min-height: 500px;
}