div.Sponsors h2 {
    margin-top: 30px;
    border: 1px solid var(--secondary-text-color);
    box-shadow: 0px 0px 5px 2px var(--secondary-text-color);
    padding: 10px;
}

div.Sponsors div.sponsor-level {
    /* margin: 25px 0 25px 0; */
    /* padding: 25px 0 25px 0; */
}

div.Sponsors div.sponsor-level div.sponsorName {
    text-align: center;
}


div.Sponsors div.sponsor-level h3 {
    padding: 10px;
    margin-top: 10px;
}

div.Sponsors div.sponsors-thank-you {
    border: #000000 solid 1px;
    background-image: url(images/SponsorsThanks.png);
    border-radius: var(--normal-border-radius);
    margin: 20px !important;
}

div.Sponsors div.sponsors-thank-you p {
    color: white;
}

div.Sponsors div.sprinter-sponsor div.sponsorName {
    margin-top: 10px;
}