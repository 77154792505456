table#registration-fees-table {
	xbackground-color: orange;
}

table#registration-fees-table tr.current-period td {
	color: var(--secondary-text-color);
	font-weight: 500;
}

table#registration-fees-table tr.expired-period td {
	color: #aaa;
	text-decoration: line-through;
}
