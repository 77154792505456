img.sponsor-logo {
    width: 90%;
    margin: 10px;
    border-radius: var(--normal-border-radius);
}

div.Sponsor a {
    text-decoration: none;
    color: inherit;
}

div.Sponsor a:hover {
    text-decoration: underline;
    color: #00000080;
}

div.Sponsor a img.sponsor-logo:hover {
    border: #000000 solid 1px;
}

div.sprinter-sponsor {
    margin-top: 0;
    padding-top: 0;
}

.extra-image-padding .sponsor-logo {
    padding: 20px;
}

.add-image-background-color .sponsor-logo {
    background-color: #999999;
}

.extra-padding {
    padding: 20px;
}

.add-background-color {
    background-color: #ffffff;
    border-radius: var(--normal-border-radius);
}

div.Sponsor {
    padding-bottom: 30px;
}