:root {
	--primary-text-color: #027402;
	--secondary-text-color: #e84545;
	--main-bg-color: #e4e4e4f0;
	--nav-text-hover-color: #BBB;
	--primary-bg-color: #e84545;
	--secondary-bg-color: #027402;
	--button-bg-color: #e84545;
	--button-text-color: white;
	--button-bg-hover-color: #c75757;
	--button-text-hover-color: #b5b5b5;
	--normal-border-radius: 10px;
}

* {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-image: url(images/hero-bg.jpg);
	background-size: cover;
	background-position: center;
}

.btn-primary {
	background-color: var(--main-bg-color) !important;
	border: 2px solid var(--button-bg-color) !important;
	margin: 5px;
}

.btn-primary a {
	color: var(--secondary-text-color);
	text-decoration: none;
	font-weight: 500;
}

.btn-primary a:hover {
	color: var(--primary-text-color);
}

.btn-primary a svg {
	fill: var(--secondary-text-color);
}

add-to-calendar-button::part(atcb-button) {
	background-color: var(--main-bg-color);
	border: 2px solid var(--secondary-text-color);
	color: var(--secondary-text-color);
}

div.display-card {
	border: 1px solid var(--primary-bg-color);
	border-radius: var(--normal-border-radius);
	padding: 20px;
	height: 95%;
}

a {
	color: var(--secondary-text-color) !important;
	font-weight: 500 !important;
	text-decoration: none !important;
}

a:hover {
	color: var(--primary-text-color) !important;
}


code,
address {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@font-face {
	font-family: Lobster;
	src: url(fonts/Lobster.ttf);
}

@font-face {
	font-family: OpenSans;
	src: url(fonts/OpenSans.ttf);
}

@font-face {
	font-family: Raleway;
	src: url(fonts/Raleway.ttf);
}

.banner {
	background-size: cover;
	background-position: center;
	border: #00000020 solid 1px;
}

.herocontent {
	padding-top: 2em;
}

.green-background {
	background-color: #0c3c0c;
	background-image: url(images/SponsorsThanks.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

* {
	color: var(--primary-text-color);
}

p {
	font-family: OpenSans;
}

.lobster {
	font-family: Lobster;
}

.button {
	background-color: var(--button-bg-color);
	color: var(--button-text-color);
	padding: 0.5em 2em;
	text-decoration: none;
	border-radius: var(--normal-border-radius);
}

.button-accent:hover,
.button-accent:focus {
	background: var(--button-bg-hover-color);
	color: var(--button-text-hover-color);
}

.mainbutton {
	margin: 2em;
	margin-bottom: 1em;
	font-size: 1em;
}

.button-caption {
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 0;
	font-size: 1.1em;
}

table.dynamic-width {
	width: auto;
}

.photo {
	width: 90%;
	margin: 10px;
	border-radius: var(--normal-border-radius);
}



div.accordion div.accordion-item {
	background-color: inherit;
	border: none;
	margin-top: 5px;
}

div.accordion div.accordion-item h2.accordion-header button.accordion-button {
	border: 1px solid var(--primary-bg-color);
	border-radius: var(--normal-border-radius);
	background-color: inherit;
}

div.accordion div.accordion-item h2.accordion-header button.accordion-button h2 {
	font-size: 1.4em;
}