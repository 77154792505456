div.Home {
  text-align: center;
  font-size: 1.2rem;
}

div.Home h1 {
  font-size: 3rem;
  font-weight: 700;
  color: var(--secondary-text-color)
}

div.Home .calendar-info {
  font-size: 0.8rem;
}