nav.navbar {
	background-color: var(--main-bg-color);
	border: 1px solid var(--secondary-bg-color);
}

nav.navbar .navbar-brand {
	color: var(--primary-text-color);
	font-weight: 500;
}

nav.navbar a.nav-link a {
	color: var(--secondary-text-color);
	font-weight: 500;
}

nav.navbar a.nav-link a:hover {
	color: var(--primary-text-color);
}
