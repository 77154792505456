div.Footer {
    min-height: 50px;
    padding: 1em;
    font-size: 0.8em;
}

div.Footer a:hover,
.dropdown:hover .dropbtn {
    color: var(--nav-text-hover-color);
}

div.Footer a {
    text-decoration: none;
}

img.socials {
    max-width: 36px;
}